/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import "../scss/modules/layout.scss"
import { graphql, StaticQuery } from "gatsby"
import FloatingBtn from "./floating-btn"
import RdvBtn from "./rdv-btn"
import ResultsBtn from "./results-btn"

const Links = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allStrapiUrl {
            nodes {
              ResultatExamenSite1
              PrendreRendeVousSite1
              ResultatExamenSite2
              PrendreRendeVousSite2
            }
          }
        }
      `}
      render={data => (
        <div className={"main-links-bottom-responsive"}>
          <RdvBtn data={data}/>
          <ResultsBtn data={data}/>
        </div>
      )}
    />
  )
}
const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div className={"layout"}>
        <main className={"main"}>
          {children}

          <Links />
        </main>
      </div>
      <FloatingBtn/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
